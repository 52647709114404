import * as React from 'react'
import { useDispatch } from 'react-redux'
import { FunctionComponent, useCallback, useRef, useState } from 'react'
import { push } from 'connected-react-router'
import AuthLayout from './AuthLayout'
import Alert from '../components/Alert'
import Input from '../components/Input'
import Button from '../components/Button'
import { requestLogin } from './authActions'

const Login: FunctionComponent = () => {
  const dispatch = useDispatch()

  const resetPasswordUrl = '/password/forgot'

  const [error, setError] = useState<string | null>(null)
  const [fetching, setFetching] = useState(false)

  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const onSubmit = useCallback(async e => {
    e.preventDefault()
    setFetching(true)
    const error = (await dispatch(
      requestLogin(emailRef.current!.value, passwordRef.current!.value)
    )) as any
    setFetching(false)
    if (error) {
      setError(error)
    } else {
      dispatch(push('/'))
    }
  }, [])

  return (
    <AuthLayout>
      <form onSubmit={onSubmit}>
        {error && <Alert>{error}</Alert>}

        <div className="form_group">
          <Input label="email" ref={emailRef} />
        </div>
        <div className="form_group">
          <Input
            label="password"
            type="password"
            autoComplete="current-password"
            ref={passwordRef}
          />
        </div>

        <div className="form_buttons">
          <Button
            type="submit"
            disabled={fetching}
            loading={fetching}
            className="form_control -size"
            caliber="sm"
          >
            Sign in
          </Button>
          {resetPasswordUrl && (
            <a className="button -sm primary-link" href={resetPasswordUrl}>
              Forgot password
            </a>
          )}
        </div>
      </form>
    </AuthLayout>
  )
}

export default Login
