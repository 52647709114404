const BRANCHES_PRODUCT_FRAGMENT = `
fragment branchesProduct on BranchesProduct {
  id
  unit
  quantity
}
`

const GENERIC_PRODUCT_FRAGMENT = `
fragment genericProduct on GenericProduct {
  id
  code
  name
  description
  photo
  salesUnit
  price
  unit
  quantity
}
`

const DIVISION_PRODUCT_FRAGMENT = `
fragment divisionProduct on DivisionsProduct {
  id
  code
  name
  description
  photo
  salesUnit
  price
  unit
  quantity
  kind
}
`

const ACCOUNT_PRODUCT_FRAGMENT = `
fragment accountProduct on AccountsProduct {
  id
  code
  name
  description
  photo
  salesUnit
  price
  unit
  kind
  erpCatalogName
}
`

const PRODUCT_FRAGMENT = `
fragment product on Product {
  id
  code
  name
  description
  photo
  salesUnit
  kind
}
`

export const ACCOUNTS_QUERY = `
query AccountFilter($first: Int, $after: String, $before: String, $text: String, $accountId: Int) {
  internal {
    accounts(first: $first, after: $after, before: $before, text: $text, id: $accountId) {
      nodes {
        id
        name
        navAccountId
        navSalesCode
        live
        orderApproveFlow
        ordersEnabled
        erpCatalogs
        replenishmentPlanVersion
        branches {
          id
          name
        }
        lastSync {
          id
          status
          accountId
          finishedAt
          startedAt
          createdAt
          syncErrors
        }
      }
      totalItems
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
`

export const DETAILED_ACCOUNT_QUERY = `
query Account($id: Int, $year: Int, $month: Int) {
  internal {
    accounts(id: $id) {
      nodes {
        id
        name
        biLinks {
          id
          title
          url
        }
        branches {
          id
          navId
          plannedProductsCount
          shipmentApproveFlow
          name
          address
          address2
          city
          zip
          state
          replenishmentOrdinal
          replenishmentWeekDay
          showTechPrice
          invoiceMappingRow
          shipToCode
          sanaLoginManager {
            id
            name
            email
          }
          actualOrders: orders(kind: replenish, year: $year, month: $month) {
            kind
            status
            dueDateAt
          }
        }
      }
    }
  }
}
`

export const CATALOG_ACCOUNT_PRODUCTS_CHECKOUTABLE_QUERY = `
${BRANCHES_PRODUCT_FRAGMENT}
${DIVISION_PRODUCT_FRAGMENT}
query Account($id: Int) {
  internal {
    accounts(id: $id) {
      nodes {
        id
        name

        productsCheckoutable: accountProducts {
          ...divisionProduct

          branchesProduct {
            ...branchesProduct
          }
        }
      }
    }
  }
}
`

export const MANAGER_CATALOG_ACCOUNT_PRODUCTS_CHECKOUTABLE_QUERY = `
${BRANCHES_PRODUCT_FRAGMENT}
${DIVISION_PRODUCT_FRAGMENT}
query Account {
  manager {
    accounts {
      id
      name

      productsCheckoutable: accountProducts {
        ...divisionProduct

        branchesProduct {
          ...branchesProduct
        }
      }
    }
  }
}
`

export const CATALOG_ACCOUNT_PRODUCTS_ORDERABLE_QUERY = `
${BRANCHES_PRODUCT_FRAGMENT}
${ACCOUNT_PRODUCT_FRAGMENT}
query Account($id: Int, $branchId: Int!, $term: String, $showAll: Boolean) {
  internal {
    accounts(id: $id) {
      nodes {
        id
        name

        productsOrderable(branchId: $branchId, term: $term, showAll: $showAll) {
          ...accountProduct

          branchesProduct {
            ...branchesProduct
          }
        }
      }
    }
  }
}
`

export const MANAGER_CATALOG_ACCOUNT_PRODUCTS_ORDERABLE_QUERY = `
${BRANCHES_PRODUCT_FRAGMENT}
${ACCOUNT_PRODUCT_FRAGMENT}
query Account($branchId: Int!, $term: String, $showAll: Boolean) {
  manager {
    account {
      id
      name

      productsOrderable(branchId: $branchId, term: $term, showAll: $showAll) {
        ...accountProduct

        branchesProduct {
          ...branchesProduct
        }
      }
    }
  }
}
`

export const CATALOG_ACCOUNT_PRODUCTS_QUERY = `
${BRANCHES_PRODUCT_FRAGMENT}
${ACCOUNT_PRODUCT_FRAGMENT}
${DIVISION_PRODUCT_FRAGMENT}
query Account($id: Int) {
  internal {
    accounts(id: $id) {
      nodes {
        id
        name

        accountProducts {
          ... on AccountsProduct {
            ...accountProduct

            branchesProduct {
              ...branchesProduct
            }
          }

          ... on DivisionsProduct {
            ...divisionProduct

            branchesProduct {
              ...branchesProduct
            }
          }
        }
      }
    }
  }
}
`

export const MANAGER_CATALOG_ACCOUNT_PRODUCTS_QUERY = `
${BRANCHES_PRODUCT_FRAGMENT}
${ACCOUNT_PRODUCT_FRAGMENT}
${DIVISION_PRODUCT_FRAGMENT}
query Account {
  manager {
    account {
      id
      name

      accountProducts {
        ... on AccountsProduct {
          ...accountProduct

          branchesProduct {
            ...branchesProduct
          }
        }

        ... on DivisionsProduct {
          ...divisionProduct

          branchesProduct {
            ...branchesProduct
          }
        }
      }
    }
  }
}
`

export const CATALOG_ACCOUNT_QUERY = `
${PRODUCT_FRAGMENT}
query Account($id: Int) {
  internal {
    accounts(id: $id) {
      nodes {
        id
        name
        catalog {
          ...product
        }
      }
    }
  }
}
`

export const MANAGER_CATALOG_ACCOUNT_QUERY = `
${PRODUCT_FRAGMENT}
query Account {
  manager {
    account {
      nodes {
        id
        name
        catalog {
          ...product
        }
      }
    }
  }
}
`

const ACCOUNT_USER_ATTRIBUTES = `
id
name
role
email
avatar
lastSignInAt
updatedAt
branches {
  id
  name
}
`

const TECHNICIAN_FIELDS_FRAGMENT = `
id
name
email
active
role
isRemovable
isMovable
lastSignInAt
updatedAt
`

const USER_FIELDS_FRAGMENT = `
id
name
email
active
role
checkoutManager
generalManager
isRemovable
isMovable
lastSignInAt
updatedAt
`


const ACCOUNT_USER = `
... on ManagerUser {
  ${ACCOUNT_USER_ATTRIBUTES}
}
... on TechnicianUser {
  ${ACCOUNT_USER_ATTRIBUTES}
  divisions {
    id
    name
  }
  teams {
    id
    name
  }
}
`

const TEAM_FRAGMENT = `
fragment team on Team {
  id
  name
  technicians {
    id
    name
    email
  }
}`

const DIVISION_FRAGMENT = `
fragment division on Division {
  id
  name
  technicians {
    id
    name
    email
  }
  products: checkoutableProducts {
    id
    code
    name
    photo
    salesUnit
    unit
    quantity
    price
    kind
  }
}
`

export const USERS_ACCOUNT_QUERY = `
${DIVISION_FRAGMENT}
query Account($id: Int) {
  internal {
    accounts(id: $id) {
      nodes {
        id
        name
        branches {
          id
          name
        }
        users { ${ACCOUNT_USER} }
        divisions { ...division }
      }
    }
  }
}
`

export const UPLOAD_ACCOUNTS_QUERY = `
mutation UploadAccounts {
  loadAccountsCsv {
    accounts: result {
      id
      name
      navAccountId
      navSalesCode
      branches {
        id
        name
      }
    }
    errors
  }
}
`

export const UPLOAD_BRANCHES_QUERY = `
mutation UploadBranches($accountId: String!) {
  loadBranchesCsv(accountId: $accountId) {
    branches: result {
      id
      navId
      plannedProductsCount
      shipmentApproveFlow
      name
      address
      address2
      city
      zip
      state
      replenishmentOrdinal
      replenishmentWeekDay
      invoiceMappingRow
      sanaLoginManager {
        id
        name
        email
      }
      showTechPrice
      actualOrders: orders(kind: replenish) {
        kind
        status
        dueDateAt
      }
    }
    errors
  }
}
`

export const UPLOAD_TECHNICIANS_QUERY = `
mutation UploadTechnicians($accountId: String!) {
  loadTechniciansCsv(accountId: $accountId) {
    technicians: result {
      ${ACCOUNT_USER_ATTRIBUTES}
      divisions {
        id
        name
      }
      teams {
        id
        name
      }
    }
    errors
  }
}
`

export const UPLOAD_SHIPMENTS_QUERY = `
mutation UploadShipments($accountId: String!) {
  loadShipmentsCsv(accountId: $accountId) {
    success
  }
}
`

export const UPLOAD_PRODUCTS_QUERY = `
mutation UploadDivisionProducts($branchId: String!) {
  loadDivisionProductsCsv(branchId: $branchId) {
    errors
    result { id }
  }
}
`

export const SET_BRANCHES_QUERY = `
mutation SetBranches($id: Int!, $branches: [Int]!) {
  userSetBranches(id: $id, branches: $branches) {
    errors
    result {
      ... on ManagerUser {
        branches {
          id
          name
        }
      }
      ... on TechnicianUser {
        branches {
          id
          name
        }
      }
    }
  }
}
`

export const SET_AVATAR_QUERY = `
mutation AttachAvatar($id: String!) {
  userAttachAvatar(id: $id) {
    errors
    result {
      ... on ManagerUser {
        avatar
      }
      ... on TechnicianUser {
        avatar
      }
    }
  }
}
`

export const CREATE_BRANCH_QUERY = `
mutation BranchCreate($attributes: BranchCreateAttributes!) {
  branchCreate(attributes: $attributes) {
    errors
    result {
      id
    }
  }
}
`

export const UPDATE_BRANCH_QUERY = `
mutation BranchUpdate($id: Int!, $attributes: BranchUpdateAttributes!) {
  branchUpdate(id: $id, attributes: $attributes) {
    errors
    result {
      id
      shipmentApproveFlow
    }
  }
}
`

export const SCHEDULE_ACCOUNT_SYNC_QUERY = `
mutation ScheduleAccountSync($id: Int!) {
  scheduleAccountSync(accountId: $id) {
    errors
    result {
      status
      startedAt
      accountId
    }
  }
}
`

export const CREATE_TECHNICIAN_QUERY = `
mutation TechnicianCreate($attributes: TechnicianCreateAttributes!) {
  technicianCreate(attributes: $attributes) {
    errors
    result {
      ${TECHNICIAN_FIELDS_FRAGMENT}
      divisions {
        id
        name
      }
      teams {
        id
        name
      }
    }
  }
}
`

export const CREATE_USER_QUERY = `
mutation UserCreate($attributes: UserCreateAttributes!) {
  userCreate(attributes: $attributes) {
    errors
    result {
      ${USER_FIELDS_FRAGMENT}
    }
  }
}
`

export const UPDATE_USER_QUERY = `
mutation UserUpdate($id: Int!, $attributes: UserAttributes!) {
  userUpdate(id: $id, attributes: $attributes) {
    errors
    result {
      ${USER_FIELDS_FRAGMENT}
    }
  }
}
`
export const UPDATE_TECHNICIAN_QUERY = `
mutation TechnicianUpdate($id: Int!, $attributes: TechnicianAttributes!) {
  technicianUpdate(id: $id, attributes: $attributes) {
    errors
    result {
      ${TECHNICIAN_FIELDS_FRAGMENT}
      divisions {
        id
        name
      }
      teams {
        id
        name
      }
    }
  }
}
`

export const REMOVE_TECHNICIAN_QUERY = `
mutation RemoveTechnician($id: Int!) {
  technicianRemove(id: $id) {
    errors
  }
}
`

export const UPDATE_MANAGER_QUERY = `
mutation ManagerUpdate($id: Int!, $attributes: ManagerAttributes!) {
  managerUpdate(id: $id, attributes: $attributes) {
    errors
    result { ${ACCOUNT_USER_ATTRIBUTES} }
  }
}
`

export const UPDATE_CHECKOUTABLE_PRODUCT_QUERY = `
${GENERIC_PRODUCT_FRAGMENT}
mutation UpdateDivision($accountId: Int!, $productId: Int!, $attributes: CheckoutableProductUpdateAttributes!) {
  checkoutableProductUpdate(accountId: $accountId, productId: $productId, attributes: $attributes) {
    errors
    result {
      ...genericProduct
    }
  }
}
`

export const CREATE_CHECKOUTABLE_PRODUCT_QUERY = `
${GENERIC_PRODUCT_FRAGMENT}
mutation CreateDivision($attributes: CheckoutableProductCreateAttributes!) {
  checkoutableProductCreate(attributes: $attributes) {
    errors
    result {
      ...genericProduct
    }
  }
}
`

export const BRANCH_DELETE = `
mutation BranchDelete($branchId: Int!) {
  branchDelete(id: $branchId) {
    errors
    result {
      id
      name
    }
  }
}
`

export const STORE_ACCOUNT_LINKS_QUERY = `
mutation StoreBiLinks($accountId: Int!, $links: [BiLinkAttributes!]!) {
  storeBiLinks(accountId: $accountId, attributes: $links) {
    result {
      id
      title
      url
    }
    errors
  }
}
`

export const ACCOUNT_UPDATE_QUERY = `
mutation AccountUpdate($id: Int!, $attributes: AccountBaseAttributes!) {
  accountUpdate(id: $id, attributes: $attributes) {
    errors
    result {
      id
      name
      live
      orderApproveFlow
    }
  }
}
`
